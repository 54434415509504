import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Confirmation } from "page_components/contact"

const InvestmentThanks = ({ pageContext, data }) => {
  return (
    <Layout
      seo={{
        title: `Dziękujemy - ${pageContext?.data?.title}`,
        description:
          "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs title={`Dziękujemy - ${pageContext?.data?.title}`} />
      <PageHeader title="Twoja wiadomość została wysłana" />
      <Confirmation />
    </Layout>
  )
}

export default InvestmentThanks
